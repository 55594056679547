import * as React from "react";
import { Link } from "gatsby";

const Navbar = () => {
  return (
    <>
      <Link className="link" to={`/`}>
        <nav className="navbar bg-primary">
          <div className="container-fluid">
            <span className="navbar-brand mb-0 h1">
              <div className="neon">burcuh. </div>
              <div className="flux">dev </div>
            </span>
          </div>
        </nav>
      </Link>
    </>
  );
};

export default Navbar;
